<template>
  <div>
    <header class="search-top">
      <aside>
        <el-select v-model="first" placeholder="请选择一级分类" filterable @change="getCategory(first,1)">
          <el-option v-for="item in firstList" :key="item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
        </el-select>
        <el-select v-model="second" placeholder="请选择二级分类" filterable @change="getCategory(second,2)">
          <el-option v-for="item in secondList" :key="'sec-'+item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
        </el-select>
        <el-select v-model="params.category_platform_id" filterable placeholder="请选择三级分类" @change="handle">
          <el-option v-for="item in thirdList" :key="'third'+item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
        </el-select>
        <el-button type="primary" @click="getList">查询</el-button>
      </aside>
      <el-button v-if="flag" type="primary" @click="create">添加</el-button>
      <div class="grant-input">
        <div>导入</div>
        <input ref="file" type="file" @change="getFile" />
      </div>
    </header>
    <DragTable :header="header" :list-query="list" :sort="true" @change="sort">
      <template #image="{row}"><img class="site-img" :src="row.image" /></template>
      <template #status="{row}">{{row.status === 0?'停用':'启用'}}</template>
      <template #control="{row}">
        <div class="control">
          <span class="word-color" @click="create(row)">编辑</span>
          <span class="word-color red" @click="ctl(row,'del')">删除</span>
          <span class="word-color" v-if="row.status == 1" @click="ctl(row,'stop')">停用</span>
          <span class="word-color" v-else @click="ctl(row,'play')">启用</span>
        </div>
      </template>
    </DragTable>
    <go-dialog v-model="visible">
      <h2>{{obj.id?'编辑':'新增'}}搜索条件分类</h2>
      <section class="item">
        <aside>站内分类：</aside>
        <span>{{obj.typeName}}</span>
      </section>
      <section class="item">
        <aside>搜索条件标题：</aside>
        <el-input v-model="obj.keyword_title" placeholder="请输入搜索条件标题"></el-input>
      </section>
      <section class="item">
        <aside>搜索条件文本：</aside>
        <el-input v-model="obj.keyword_search" placeholder="请输入搜索条件文本"></el-input>
      </section>
      <upload :info="imgInfo" class="upload-img" @getimage="getChange" />
      <footer>
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from '@vue/composition-api'
import Upload from "@/components/common/Upload";

export default defineComponent({
  components: { Upload },
  setup(props,{ root, refs }) {
    const visible = ref(false)
    const flag = ref(false)
    const str = ref('')
    const params = reactive({
      is_show_page: 0,
      category_platform_id: '',
    })
    const imgInfo = reactive({
      url: '',
      width: 200,
      height: 200,
      requestUrl: '/common/uploadImageOss',
      pathType: 'site_category'
    })
    const first = ref('')
    const second = ref('')
    const header = [
      { name: "序号", value: "eq" },
      { name: "三级分类", value: "three_level_name" },
      { name: "搜索条件标题", value: "keyword_title" },
      { name: "搜索条件文本", value: "keyword_search" },
      { name: "图片", value: "image", slot: 'image' },
      { name: "状态", value: "status", slot: 'status' },
      { name: "操作", slot: 'control', width: 140 },
    ]
    const list = reactive({data: []})
    const firstList = ref([])
    const secondList = ref([])
    const thirdList = ref([])
    let obj = reactive({
      id: '',
      typeName: '',
      keyword_title: '',
      keyword_search: '',
      three_level_name: '',
      two_level_name: '',
      one_level_name: '',
      image: '',
      category_platform_id: ''
    })
    const getList = () => {
      if(!params.category_platform_id) {
        root.$message.warning('请选择完分类后查询')
        return
      }
      root.$axios.get(`/category/searchWord/index`, {
        params
      })
      .then(res=>{
        res.data.result.forEach((item,i)=>{
          item.eq=i+1
        })
        list.data = res.data.result
        flag.value = true
      })
      
    }
    const getCategory = (num,type) => {
      list.data = []
      flag.value = false
      if(!num && num !== 0) return
      root.$axios.get(`/category/platform/screenList`,{
        params: {
          p_id: num
        }
      })
      .then(res=>{
        if(type == 0) {
          firstList.value = res.data
          second.value = ''
          params.category_platform_id = ''
          secondList.value = []
          thirdList.value = []
        } else if(type == 1) {
          second.value = ''
          secondList.value = res.data
          thirdList.value = []
          params.category_platform_id = ''
        } else if(type == 2) {
          thirdList.value = res.data
          params.category_platform_id = ''
        }
      })
      
    }
    getCategory(0,0)
    const create = (data) => {
      obj.typeName = str.value
      if(data.id) {
        root.$axios.get(`/category/searchWord/show/${data.id}`)
        .then(res=>{
          imgInfo.url = res.data.image
          Object.assign(obj,res.data)
          visible.value = true
        })
        
      } else {
        Object.keys(obj).forEach(key=>{
          if(key != 'category_platform_id' && key != 'typeName') {
            obj[key] = ''
          }
        })
        visible.value = true
      }
    }
    const getChange = e => {
      obj.image = e
    }
    const handle = (num)=>{
      str.value = ''
      list.data = []
      flag.value = false
      obj.category_platform_id = num
      firstList.value.forEach(item=>{
        if(item.category_platform_product_id == first.value) {
          str.value+=item.name+'/'
        }
      })
      secondList.value.forEach(item=>{
        if(item.category_platform_product_id == second.value) {
          str.value+=item.name+'/'
        }
      })
      thirdList.value.forEach(item=>{
        if(item.category_platform_product_id == num) {
          str.value+=item.name
        }
      })
    }
    const ctl = async (data,type)=>{
      let str = ''
      let url = '/category/searchWord/statusChange'
      let status = null
      switch(type){
        case 'del': str = '确定要删除吗？'
        url = '/category/searchWord/delete'
        break;
        case 'stop': str = '确定要停用吗？'
        status = 0
        break;
        case 'play': str = '确定要启用吗？'
        status = 1
        break;
      }
      const confirm = await root.$goDialog(str)
      if(!confirm) return
      root.$axios.post(url,{
        status,
        id: data.id
      })
      .then(res=>{
        root.$message.success(res.msg)
        getList()
      })
      
    }
    const cancel = () => {
      imgInfo.url = ''
      visible.value = false
    }
    const confirm = () => {
      if(!obj.keyword_title) {
        root.$message.warning('请输入搜索条件标题')
        return
      }
      if(!obj.keyword_search) {
        root.$message.warning('请输入搜索条件文本')
        return
      }
      if(!obj.image) {
        root.$message.warning('请选择图片')
        return
      }
      let url = '/category/searchWord/store'
      if(obj.id) {
        url = '/category/searchWord/update'
      }
      root.$axios.post(url, obj)
      .then(res=>{
        root.$message.success(res.msg)
        getList()
        cancel()
      })
      
    }
    const getFile = () => {
      const file = refs.file.files[0];
      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const formData = new FormData()
      formData.append('excel_file', file)
      root.$axios.post('/category/searchWord/import',formData,config)
      .then(res=>{
        root.$message.success(res.msg)
        // getList()
      })
      
    };
    const sort = async (data) => {
      try {
        const arr = []
        data.forEach((item,i) => {
          arr.push({ id: item.id, sort: i+1 })
        });
        await root.$axios.post('/category/searchWord/sort',{
          sort_list: arr
        })
        getList()
      } catch (error) {
        getList()
        root.$message.error(error)
      }
    }
    return {
      flag,
      imgInfo,
      params,
      list,
      header,
      first,
      second,
      firstList,
      secondList,
      thirdList,
      visible,
      obj,
      getList,
      getCategory,
      ctl,
      create,
      handle,
      cancel,
      confirm,
      getChange,
      sort,
      getFile
    }
  },
})
</script>
<style lang="scss" scoped>
.search-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  aside{
    &>div{
      margin-right: 15px;
    }
  }
}
.item{
  display: flex;
  align-items: center;
  margin-top: 15px;
  &>aside{
    word-spacing: normal;
    white-space: nowrap;
  }
}
.upload-img{
  height: 150px;
  margin-top: 15px;
}
footer{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.control{
  display: flex;
  span{
    margin-right: 15px;
  }
}
.grant-input {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 84px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #7a7c81;
  line-height: 32px;
  margin-right: 15px;
  background-color: $mainColor;
  div {
    font-size: 14px;
    color: #fff;
  }
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
</style>